/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import classes from "./superAdmin.module.css";
import UserContext from "../../context/user/userContext";
import Bloglist from '../../blogComponents/Bloglist.jsx'
import axios from "axios";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { RiSearch2Line } from "react-icons/ri";
import Skeleton from "../../skeleton/skeleton.jsx";


const url = process.env.REACT_APP_BACKEND_SERVER_URL;
export default function SuperAdmin() {
  const { allArtistes } = useContext(
    UserContext
  );

  let premiumUsers = "";
  let freeUsers = "";
  let independentArtistes = "";
  let miniLabel = "";
  let bigLabel = "";
  let allSongs = [];
  let allAlbums = [];


  const [data, setData] = useState({
    totalCount: 0,
    limit: 100,
    page: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    users: []
  })

  const [metrics, setMetrics] = useState(null)

  const { users } = data
  const [searchValues, setSearchValues] = useState({
    page: 1,
    searchQuery: ''
  })

  const [loading, setLoading] = useState(false)
  const [loadingMetrics, setLoadingMetrics] = useState(false)

  const { page, searchQuery } = searchValues

  useEffect(() => {
    const getAllUsers = async () => {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("soundmac3_token")}`,
        },
      };
      setLoading(true)

      try {
        const res = await axios.get(`${url}/users?page=${page}&searchQuery=${searchQuery}&paginate=${true}`, config);
        const data = res.data;
        setData(data);


      } catch (error) {
        const msg = error?.response?.data?.msg || 'something went wrong'
        alert(msg);

      } finally {
        setLoading(false)
      }
    };
    getAllUsers()

  }, [searchValues])


  useEffect(() => {
    const getMetrics = async () => {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("soundmac3_token")}`,
        },
      };
      setLoadingMetrics(true)

      try {
        const res = await axios.get(`${url}/users/metric`, config);
        const data = res.data;
        setMetrics(data);


      } catch (error) {
        const msg = error?.response?.data?.msg || 'something went wrong'
        alert(msg);

      } finally {
        setLoadingMetrics(false)
      }
    };

    getMetrics()
  }, [])




  return (
    <div className={classes.container}>
      <p className={classes.title}>Overview</p>

      <div className={classes.metric}>
        <MetricBox
          figure={metrics?.registeredUsers}
          label_1={"registered"}
          label_2={"users"}
          loadingMetrics={loadingMetrics}
          background={"#fff"}
        />

        <MetricBox
          figure={metrics?.premiumUsers}
          label_1={"premium"}
          label_2={"users"}
          loadingMetrics={loadingMetrics}
          background={"#fff"}
        />

        <MetricBox
          figure={metrics?.freeUsers}
          label_1={"free"}
          label_2={"users"}
          loadingMetrics={loadingMetrics}
          background={"#fff"}
        />

        <MetricBox
          figure={metrics?.independentArtiste}
          label_1={"independent"}
          label_2={"artistes"}
          loadingMetrics={loadingMetrics}
          background={"#E74C3C"}
          color={"#fff"}
        />

        <MetricBox
          figure={metrics?.miniLabel}
          label_1={"mini"}
          label_2={"labels"}
          loadingMetrics={loadingMetrics}
          background={"#7F64FE"}
          color={"#fff"}
        />

        <MetricBox
          figure={metrics?.label}
          label_1={"big"}
          label_2={"labels"}
          loadingMetrics={loadingMetrics}
          background={"#7FC6A6"}
          color={"#fff"}
        />



      </div>



      <div >
        <div className={classes.search_container}>
          <RiSearch2Line className={classes.search_icon} />
          <input onChange={(e) => { setSearchValues((prev) => ({ ...prev, searchQuery: e.target.value })) }} placeholder='Search first name, last name, country, email, account type' type='text' className={classes.search_input} />
        </div>


        {loading ? (
          <div className={classes.loader_con}>
            <Skeleton width="100%" height="100%" />
          </div>
        ) : (
          users && users.length > 0 ? (
            <div className={classes.table_container}>

              <p className={classes.showing}>
                Showing: <span>1 - {users.length} of {data.totalCount}</span>
              </p>

              <div className={classes.scrollable}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email Address</th>
                      <th>Country</th>
                      <th>Account Type</th>
                      <th>Premium</th>
                      <th>Premium Expiration</th>
                      <th>Warning</th>
                      <th>Banned</th>
                      <th>Withdrawal Eligibility</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.users.map((item, index) => (
                      <tr key={index}>
                        <td >{index + 1}</td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.first_name}</p>
                          <p className={classes.tooltip}>{item.first_name}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.last_name}</p>
                          <p className={classes.tooltip}>{item.last_name}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.email}</p>
                          <p className={classes.tooltip}>{item.email}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.country}</p>
                          <p className={classes.tooltip}>{item.country}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.type}</p>
                          <p className={classes.tooltip}>{item.type}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.premium.toString()}</p>
                          <p className={classes.tooltip}>{item.premium.toString()}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.premiumExpiration || null}</p>
                          <p className={classes.tooltip}>{item.premiumExpiration || null}</p>
                        </td>

                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.warning}</p>
                          <p className={classes.tooltip}>{item.warning}</p>
                        </td>

                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.banned.toString()}</p>
                          <p className={classes.tooltip}>{item.banned.toString()}</p>
                        </td>


                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.withdrawalEligibility.toString()}</p>
                          <p className={classes.tooltip}>{item.withdrawalEligibility.toString()}</p>
                        </td>


                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className={classes.page_icons_grouped}>
                <p>Page: <span>{data.page}</span></p>
                <div
                  onClick={() => { setSearchValues((prev) => ({ ...prev, page: parseInt(data.page) - 1 })) }}
                  className={data.hasPreviousPage ? classes.angle_icon_container : classes.angle_icon_container_inactive}
                >
                  <FaAngleLeft className={classes.angle_icon} />
                </div>
                <div
                  onClick={() => { setSearchValues((prev) => ({ ...prev, page: parseInt(data.page) + 1 })) }}
                  className={data.hasNextPage ? classes.angle_icon_container : classes.angle_icon_container_inactive}
                >
                  <FaAngleRight className={classes.angle_icon} />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.empty_container}>
              <p className={classes.empty_message}>
                {searchQuery ? `No user found with "${searchQuery}" keywords` : (
                  <p className={classes.click_here}>
                    nothing found
                  </p>
                )}
              </p>
            </div>
          )
        )}

      </div>
      <Bloglist />
    </div>
  );
}

function MetricBox({ figure, label_1, label_2, color, background, loadingMetrics }) {
  return (
    <div style={{ background: background }} className={classes.metric_box_1}>
      {
        loadingMetrics ?
          <Skeleton width={'100%'} height={'100%'} />
          :
          <>
            <p style={{ color: color }} className={classes.metric_figure}>
              {figure}
            </p>
            <p style={{ color: color }} className={classes.metric_label}>
              {label_1}
              <br />
              {label_2}
            </p>
          </>

      }


    </div>
  );
}
