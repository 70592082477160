import { useContext, useState } from 'react';
import classes from "./uploadSong.module.css";
import AlertContext from '../../../context/alertAndPopUp/alertAndPopUpContext';

function License({uploadValues,selectLicense}) {
  const { alert } = useContext(AlertContext);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const allowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  const handleFileChange = (e) => {
    console.log(e.target.files)
    const file = e.target.files[0];

    if (file && allowedFileTypes.includes(file.type)) {
      setAcceptedFile(file);
      
      setErrorMessage('');
    } else {
        setAcceptedFile(null);
        alert('Only .doc and .pdf files are allowed.');
    }
    selectLicense((prev) => ({
      ...prev,
      ["license"]: file,
    }))
  };

  return (
    <div className='mb-3'>
    <p className={classes.question + " "}>Add license</p>
      <input
        accept=".doc,.pdf"
        type="file"
        onChange={handleFileChange}
        className="bg-transparent border-none w-full"
        disabled={uploadValues.onlinebeats !== "yes"}
        required={uploadValues.onlinebeats === "yes"}
        name="license"
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
    </div>
  );
}

export default License;
