export const platforms = [
    "7Digital",
    "A1 Xplore Music by Deezer(Delivered via Deezer)",
    "ACRCloud",
    "Adaptr",
    "Afrochart",
    "Amazon Music",
    "AMI Entertainment",
    "Anghami",
    "Apple Music/iTunes",
    "Audible Magic",
    "AudioMack",
    "AWA",
    "Beatport",
    "Beatsource",
    "BMAT",
    "Boomplay Music (Africa)",
    "BPM Supreme",
    "Claro Música",
    "ClicknClear",
    "Clone.NL (Delivered via Juno Download)",
    "Deezer",
    "Express In Music",
    "Facebook/Instagram",
    "FLO (South Korea)",
    "Gracenote",
    "Huawei Music(Delivered via Napster)",
    "Hungama",
    "iHeartRadio",
    "Jaxsta",
    "JioSaavn",
    "JOOX",
    "Juno Download",
    "KkBox",
    "Kanjian Music",
    "Kuack Media Group",
    "Lick’d",
    "LINE Music",
    "MediaNet",
    "MePlaylist",
    "Mixcloud",
    "Mixupload",
    "MusicTime!",
    "Music Is Vivid",
    "Musiton (Puerto Rico)",
    "Napster",
    "NetEase Cloud Music",
    "Nightlife Music",
    "Pandora",
    "Peloton",
    "PortalDisc (Chile)",
    "Pretzel",
    "Qobuz",
    "Slacker Radio (Live x Live)",
    "Snapchat (Delivered via 7Digital)",
    "Shazam(Apple Music)",
    "SoundCloud (Monetization)",
    "SoundExchange",
    "Spotify",
    "Tencent Music",
    "Tidal",
    "TikTok/Resso",
    "Tim Music",
    "Touch Tunes",
    "Vibe (Naver)",
    "Volumo",
    "Wynk",
    "YouSee / Telmore Musik",
    "YouTube Content ID",
    "YouTube Music",
    "Zvuk"
  ];