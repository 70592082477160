import { useContext, useState } from "react";
import { PaystackButton } from "react-paystack";
import AlertContext from "../../context/alertAndPopUp/alertAndPopUpContext";

export default function BillboardPayment({ email, BillboardPaymentSelect,values}) {
    const [BillboardPayment, setBillboardPayment] = useState(false)
  const { alert } = useContext(AlertContext);

  const config = {
    email: email,
    amount: 500000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };

const handleSuccess = () => {
    setBillboardPayment(true);
    BillboardPaymentSelect((prev) => ({
        ...prev,
        ["billboardPayment"]: "true",
      }));
};
  const handleClose = () => {
    alert(`error making payment!`);
  };

  const isPaid = values.billboardPayment === 'true'? "Paid":"Pay"
  const componentProps = {
    ...config,
    text: isPaid,
    onSuccess: (reference) => {
        alert(`Payment complete! Reference: ${reference.reference}`)
        handleSuccess();
        console.log(values);
    },
    onClose: handleClose,
  };

  return (
    <div>
      <PaystackButton {...componentProps} className="bg-blue-700 text-white py-1 px-3 rounded-2xl bg-opacity-80 hover:bg-opacity-100 shadow-sm shadow-black/25" />
    </div>
  );
}
