import React, { useState } from 'react';
import classes from "./uploadSong.module.css";
import { platforms } from "./platforms.js";
import { ChevronDown } from 'lucide-react';


const Platforms = ({selectPlatform}) => {
      //state for the platforms 
  const [selectedPlatforms, setSelectedPlatforms] = useState(platforms);
  const [dropdownOpen, setDropdownOpen] = useState(false);


  const handleCheckboxChange = (platform) => {
    if (selectedPlatforms.includes(platform)) {
      setSelectedPlatforms(selectedPlatforms.filter((p) => p !== platform));
    } else {
      setSelectedPlatforms([...selectedPlatforms, platform]);
    }
    selectPlatform((prev) => ({
        ...prev,
        ["selectedPlatforms"]: selectedPlatforms,
      }))
  };

  return (
    <div className="relative inline-block w-full">
      {/* Dropdown button */}
      <p className={classes.question}>Select music distribution platform:  
      </p>
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="w-full p-2 bg-white border rounded-lg text-left flex justify-between"
      >
        {selectedPlatforms.length > 0 ? selectedPlatforms[0] : 'Select Platforms'}
        <ChevronDown />
      </button>

      {/* Dropdown content (list of checkboxes) */}
      {dropdownOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border rounded-lg shadow-lg">
          <div className="max-h-48 overflow-y-auto">
            {platforms.map((platform) => (
              <label key={platform} className="flex items-center p-2 hover:bg-gray-100">
                <input
                  type="checkbox"
                  className="form-checkbox text-indigo-600"
                  checked={selectedPlatforms.includes(platform)}
                  onChange={() => handleCheckboxChange(platform)}
                />
                <span className="ml-2">{platform}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Platforms;
