import classes from "./faq.module.css";

export default function FAQ() {
  return (
    <div className={classes.container}>
      <p className={classes.title}>FAQ & SUPPORT</p>
      <div className={classes.sub_container}>
        <div className={classes.content}>
          <ul className={classes.answers_group}>
            <li className={classes.answers_heading}>
              What is Soundmac, and what services do you offer?
            </li>
            <p className={classes.answers_text}>
              Soundmac is a digital music distribution platform that allows
              artists to upload and distribute their music to popular streaming
              services like Spotify, Apple Music, Boomplay, Youtube, Amazon, and
              more.
            </p>

            <li className={classes.answers_heading}>
              How do I get started with Soundmac?
            </li>
            <p className={classes.answers_text}>
              To get started with Soundmac, simply create an account on our
              website and follow the prompts to upload your music.
            </p>

            <li className={classes.answers_heading}>
              How do I upload my music?
            </li>
            <p className={classes.answers_text}>
              You can upload your music to Soundmac by logging into your
              account, clicking the "Upload Single" or “Upload Album” button,
              and following the prompts to select your Cover art, music files
              and enter the relevant information.
            </p>

            <li className={classes.answers_heading}>
              What file formats do you support for music uploads?
            </li>
            <p className={classes.answers_text}>
              Soundmac supports all audio file formats, including MP3, WAV, FLAC
              and many More more.
            </p>

            <li className={classes.answers_heading}>
              How long does it take for my music to be available on streaming
              platforms after I upload it?
            </li>
            <p className={classes.answers_text}>
              It typically takes 24 hours for soundmac to review your music and
              it a few days for your music to be available on streaming
              platforms after it has been approved by us. However, the exact
              time frame may vary depending on the streaming platform and the
              volume of uploads being processed.
            </p>

            <li className={classes.answers_heading}>
              How do I set the price for my music?
            </li>
            <p className={classes.answers_text}>
              You can set the price for your music on Soundmac by reaching out
              to support team after immediately after uploading.
            </p>

            <li className={classes.answers_heading}>
              Can I change the price of my music after it's been uploaded?
            </li>
            <p className={classes.answers_text}>
              You Yes, you can change the price of your music on Soundmac at any
              time by Reaching out to soundmac support center .
            </p>

            <li className={classes.answers_heading}>
              How do I get paid for my music sales?
            </li>
            <p className={classes.answers_text}>
              Soundmac pays out royalties to artists through a variety of
              payment methods, including PayPal, Payoneer, bank transfer, and
              more. You can set your preferred payment method in your account
              settings.
            </p>

            <li className={classes.answers_heading}>
              What is your commission fee for music sales?
            </li>
            <p className={classes.answers_text}>
              Soundmac's commission fee for music sales is 0% and give you 100%
              of your revenue generated when your registered on Premium account
              type( Independent account, Mini Label, label). Soundmac commission
              fee for music sales is 20% of the revenue generated by your music
              For “FREE ACCOUNT”.
            </p>

            <li className={classes.answers_heading}>
              How often do you pay out royalties to artists?
            </li>
            <p className={classes.answers_text}>
              Soundmac pays out royalties to artists on a monthly basis,
              typically 30th of the month following the month in which
              the sales were made.
            </p>

            <li className={classes.answers_heading}>
              Can I distribute my music on Soundmac if I'm signed to a record
              label?
            </li>
            <p className={classes.answers_text}>
              Yes, you can distribute your music on Soundmac even if you're
              signed to a record label, as long as you have the necessary rights
              to do so
            </p>

            <li className={classes.answers_heading}>
              How do I know if my music is eligible for distribution on
              Soundmac?
            </li>
            <p className={classes.answers_text}>
              Your music is eligible for distribution on Soundmac if it meets
              the platform's quality and content standards, and if you have the
              necessary rights to distribute it.
            </p>

            <li className={classes.answers_heading}>
              How do I track my music sales on Soundmac?
            </li>
            <p className={classes.answers_text}>
              You can track your music sales on Soundmac by logging into your
              account and viewing the sales and revenue data for each release in
              your catalog.
            </p>

            <li className={classes.answers_heading}>
              Can I distribute music videos on Soundmac?
            </li>
            <p className={classes.answers_text}>
              Not Yet, you can’t distribute music videos on Soundmac for now but
              as soon as we have the necessary things in place our users will be
              notified.
            </p>

            <li className={classes.answers_heading}>
              How do I promote my music on Soundmac?
            </li>
            <p className={classes.answers_text}>
              Soundmac offers a variety of promotional tools and services to
              help you promote your music, including, social media advertising,
              and more. Please contact the support team for more information on
              this.
            </p>

            <li className={classes.answers_heading}>
              Can I distribute my music internationally with Soundmac?
            </li>
            <p className={classes.answers_text}>
              Yes, Soundmac allows you to distribute your music to streaming
              services in multiple countries around the world.
            </p>

            <li className={classes.answers_heading}>
              How do I copyright my music on SoundMac?
            </li>
            <p className={classes.answers_text}>
              SoundMac does not provide copyright protection for your music. To
              copyright your music, you need to register it with the appropriate
              copyright office in your country.
            </p>

            <li className={classes.answers_heading}>
              How do I delete or remove my music from SoundMac?
            </li>
            <p className={classes.answers_text}>
              To remove your music from SoundMac, contact soundmac support team.
            </p>

            <li className={classes.answers_heading}>
              Can I distribute cover songs on SoundMac?
            </li>
            <p className={classes.answers_text}>
              Yes, you can distribute cover songs on SoundMac, but you need to
              make sure that you have obtained the necessary licenses and
              permissions from the original copyright owners.
            </p>

            <li className={classes.answers_heading}>
              How do I create a SoundMac artist profile?
            </li>
            <p className={classes.answers_text}>
              To create a SoundMac artist profile, go to SoundMac's website,
              click on the "Register" button, and follow the prompts to set up
              your profile.
            </p>

            <li className={classes.answers_heading}>
              How do I submit my music to playlists on SoundMac?
            </li>
            <p className={classes.answers_text}>
              For now the features that allows users to submit music for
              playlist is not available, soon you can submit your music to
              playlists on SoundMac by contacting the playlist curators or by
              using SoundMac's playlist submission tool.
            </p>

            <li className={classes.answers_heading}>
              How do I get featured on the SoundMac Artist of the month?
            </li>
            <p className={classes.answers_text}>
              To be featured as the Soundmac artist of the month, It's important
              to have a strong online presence, including a website and social
              media accounts, as well as high-quality recordings and engaging
              content. Networking with industry professionals and building a fan
              base can also increase the chances of being selected.
              Additionally, having a unique and distinctive style can make an
              artist stand out and catch the attention of Soundmac's team.
            </p>

            <li className={classes.answers_heading}>
              What genres of music do you support on SoundMac?
            </li>
            <p className={classes.answers_text}>
              SoundMac supports all genres of music, including Afro-beat,
              Afro-pop, hip-hop, rock, pop, electronic, country, and more.
            </p>

            <li className={classes.answers_heading}>
              Can I distribute spoken word content on SoundMac?
            </li>
            <p className={classes.answers_text}>
              Yes, you can distribute spoken word content on SoundMac, as long
              as it meets the platform's content guidelines.
            </p>

            <li className={classes.answers_heading}>
              How do I contact SoundMac customer support?
            </li>
            <p className={classes.answers_text}>
              You can contact SoundMac customer support by going to their
              website and clicking on the "Contact Us" link or send a mail to
              Support@soundmac.co .
            </p>

            <li className={classes.answers_heading}>
              Can I Edit the album/single Metadata for my music after it's been
              uploaded?
            </li>
            <p className={classes.answers_text}>
              Yes, you can change the metadata for your music on SoundMac by
              editing the track or album information in your Dashboard.
            </p>

            <li className={classes.answers_heading}>
              How do I make my music available for pre-order on SoundMac?
            </li>
            <p className={classes.answers_text}>
              To make your music available for pre-order on SoundMac, you need
              to set a release date for your album or single and then use
              SoundMac's pre-order feature to make it available for purchase
              before the release date.
            </p>

            <li className={classes.answers_heading}>
              Can I distribute live recordings on SoundMac?
            </li>
            <p className={classes.answers_text}>
              Yes, you can distribute live recordings on SoundMac, as long as
              they meet the platform's content guidelines.
            </p>

            <li className={classes.answers_heading}>
              How do I set up collaborations with other artists on SoundMac?
            </li>
            <p className={classes.answers_text}>
              This feature is not yet available. However, soon to set up
              collaborations with other artists on SoundMac, you can use the
              platform's messaging feature to connect with other artists and
              discuss potential collaborations.
            </p>

            <li className={classes.answers_heading}>
              Can I distribute instrumentals on Soundmac?
            </li>
            <p className={classes.answers_text}>
              Yes, you can distribute instrumentals on SoundMac as long as you
              have the necessary rights to do so. SoundMac allows Users to
              distribute a variety of music types, including instrumental
              tracks. However, please note that instrumental tracks may have
              different requirements or limitations compared to tracks with
              vocals or lyrics, depending on the streaming platform.
            </p>

            <li className={classes.answers_heading}>
              Can I upload lyrics of my music on soundmac?
            </li>
            <p className={classes.answers_text}>
              Yes, you can upload lyrics of your music on SoundMac. When you
              upload your Single/Album to SoundMac, there is an option to
              include the lyrics for each track. Including the lyrics can be
              helpful for fans who want to sing along or understand the meaning
              behind the song. However, please note that not all streaming
              platforms support lyrics for every region or language.
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
}
